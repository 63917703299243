<template>
  <div class="vid">
    <video
    id="videoPlayer"
      ref="videoPlayer"
      class="video-js vid-main"
      crossorigin="anonymous" controlslist="nodownload  nofullscreen noremoteplayback" oncontextmenu = "return false"
    ></video>
  </div>
</template>

<script>
import videojs from "video.js";
// const Url = "https://future.skboyed.xyz";
const Url = "https://api.futureupinc.xyz";

export default {
  name: "Vid",
  data() {
    return {
      player: null,
      videoOptions: {
        playbackRates: [0.5, 1, 1.5, 2],
        autoplay: false,
        controls: true,
        crossOrigin: "anonymous",
        sources: [
          // {
          //   src: "http://192.168.33.14:10086/下载.mp4",
          // },
        ],
      },
    };
  },
  mounted() {
    let { id } = this.$route.query;
    this.check(id);
    // document.getElementsById("videoPlayer").oncontextmenu = function () {
    //   return false;
    // };
  },
  methods: {
    check(id) {
      let that = this;
      // let old_id = document.cookie.split(";")[0].split("=")[0];
      var old_id = localStorage.getItem("id");
      // let videoUrl = document.cookie.split(";")[0].split("=")[1];
      let videoUrl = localStorage.getItem("vurl");
      if (old_id && id === old_id) {
        this.videoOptions.sources.push({
          src: videoUrl,
        });
        this.player = videojs(
          that.$refs.videoPlayer,
          that.videoOptions,
          function onPlayerReady() {}
        );
        setInterval(() => {
          this.keepA(id);
        }, 10000);
      } else {
        fetch(`${Url}/api/videoUrl/check?id=${id}`, {
          method: "POST",
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.code === 20000) {
              that.videoOptions.sources.push({
                src: data.message,
              });
              that.player = videojs(
                that.$refs.videoPlayer,
                that.videoOptions,
                function onPlayerReady() {}
              );
              localStorage.setItem("id", id);
              localStorage.setItem("vurl", data.message);
              setInterval(() => {
                this.keepA(id);
              }, 10000);
            } else {
              alert(data.message);
            }
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      }
    },
    keepA(id) {
      fetch(`${Url}/api/videoUrl/keepalive?id=${id}`, {
        method: "POST",
      })
        .then((response) => response.json())
        .then(() => {})
        .catch(() => {});
    },
  },

  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.vid-main {
  width: 100vw;
  height: 100vh;
}
</style>
